import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import loginDefaultImage from "../../images/main/user-login.png";

function LoginModal({ onClose, pathRoute, onLogin }) {
  const history = useHistory();
  const [showOTPSection, setShowOTPSection] = useState(false);
  const [showSendOTPButton, setShowSendOTPButton] = useState(true);
  const [showFormGroup, setShowFormGroup] = useState(true);
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(20);

  useEffect(() => {
    // Handle the timer for enabling the Resend OTP button
    let countdown;
    if (showOTPSection && resendDisabled) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [showOTPSection, resendDisabled]);

  const handleSendOTP = async () => {
    if (!validateMobileNumber(mobileNumber)) {
      setValidationMessage('Please enter a valid 10-digit mobile number.');
      return;
    }
    setValidationMessage(''); // Clear any previous messages
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/customer/register`, { mobileNumber });
      setShowOTPSection(true);
      setShowSendOTPButton(false);
      setShowFormGroup(false);
      setResendDisabled(true);
      setTimer(20); // Reset timer when OTP is sent
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/customer/verify-otp`, {
        mobileNumber,
        otp
      });
      if (response.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("auth_token", response.data.accessToken);
        localStorage.setItem("user_info", JSON.stringify(response.data.customer));
        localStorage.setItem("user_id", response.data.customer.id);
        localStorage.setItem("logintime", new Date());

        history.push(pathRoute);
        onLogin();
        onClose();
      } else {
        setOtpError("Incorrect OTP. Please try again or re-enter the OTP.");
      }
    } catch (error) {
      setOtpError("Incorrect OTP. Please try again or re-enter the OTP.");
      console.error(error);
    }
  };

  const handleResendOTP = async () => {
    setOtpError("");
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/customer/resend-otp`, { mobileNumber });
      setResendDisabled(true);
      setTimer(20); // Reset the timer for 20 seconds
    } catch (error) {
      console.error(error);
    }
  };

  const validateMobileNumber = (number) => /^[0-9]{10}$/.test(number);

  const handleKeyPressSendOTP = (e) => {
    if (e.key === 'Enter') handleSendOTP();
  };

  const handleKeyPressVerifyOTP = (e) => {
    if (e.key === 'Enter') handleVerifyOTP();
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values and limit length to 6 digits
    if (/^\d*$/.test(value) && value.length <= 6) setOtp(value);
  };

  return (
    <div className="defaultModal" id="userLoginModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <button onClick={onClose} type="button" className="close border-none" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <figure className="text-center">
              <img src={loginDefaultImage} alt="User Login" />
            </figure>
            <h5 className="modal-title">LOGIN USING USEDCARWALE</h5>
            <p className="s-para">for Better Experience, Order tracking & Regular updates</p>
            {showFormGroup && (
              <div className="form__group field">
                <input
                  type="text"
                  className="form__field"
                  placeholder="dealerMobNumber"
                  name="dealerMobNumber"
                  id="dealerMobNumber"
                  required
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  onKeyPress={handleKeyPressSendOTP}
                />
                <label htmlFor="dealerMobNumber" className="form__label">Enter mobile number</label>
                {validationMessage && (
                  <p id="validationMessage" className="s-para validationMessage text-danger">{validationMessage}</p>
                )}
              </div>
            )}
            <div className="form-footer">
              {showSendOTPButton && (
                <button type="button" id="submitButton" className="button" onClick={handleSendOTP}>
                  Send OTP
                </button>
              )}

              <p className="s-para">
                By continuing I agree with the <a href="#" className="theme">Privacy Policy</a>,{' '}
                <a href="#" className="theme">Terms & Conditions</a>
              </p>

              {showOTPSection && (
                <div>
                  <div className="send-otp-section">
                    <input
                      type="text"
                      id="otpInput"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleOtpChange}
                      onKeyPress={handleKeyPressVerifyOTP}
                    />
                    <button type="button" id="verifyOtpButton" className="border-none" onClick={handleVerifyOTP}>
                      Verify OTP
                    </button>
                    {otpError && (
                      <p className="error-message text-danger">{otpError}</p>
                    )}
                  </div>

                  <button
                    type="button"
                    id="resendOtpButton"
                    className="button small"
                    onClick={handleResendOTP}
                    disabled={resendDisabled}
                  >
                    {resendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
