import React, { useState } from 'react';
import Banner from './Banner';
import HighLights from './HighLights';
import RecentSearch from './RecentSearch';
import PopularBrands from './PopularBrands';
import Testimonial from './Testimonial';
import TrendingSearches from './TrendingSearches';
import EmiCalculator from './EmiCalculator';
import axios from "axios";
import "../style/Home.css";  // Import CSS styles

function Home() {
    // State for filters, car list, suggestions, and error handling
    const [carList, setCarList] = useState([]);
    const [suggestions, setSuggestions] = useState([]); // New state for suggestions
    const [noCarsFound, setNoCarsFound] = useState(false);
    const [filterData, setFilterData] = useState({
        search_text: "",
    });

    // Handler for input/select changes with real-time suggestion fetch
    const handleSelect = async (e, name) => {
        const searchText = e.target.value;
        setFilterData({
            ...filterData,
            [name]: searchText,
        });

        // Fetch suggestions if there is some input
        if (searchText) {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/car/car-list?search_text=${searchText}`
                );
                const { car } = response?.data;

                if (car.length === 0) {
                    setSuggestions([]);
                } else {
                    const extractedSuggestions = car.map(c => ({
                        id: c.id,
                        brand: c.brand.brand_name,
                        model: c.model.model_name,
                        varient: c.varient.varient_name,
                    }));
                    setSuggestions(extractedSuggestions);
                }
            } catch (error) {
                console.error("Error fetching suggestions:", error);
            }
        } else {
            setSuggestions([]); // Clear suggestions if input is empty
        }
    };

    // Fetch data from API and extract brand, model, and variant
    const fetchData = async (filterDataObj) => {
        let queryParams = new URLSearchParams();
        if (filterDataObj.search_text) {
            queryParams.append('search_text', filterDataObj.search_text);
        }

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/car/car-list?${queryParams}`
            );
            const { car } = response?.data;

            // Clear previous car list
            setCarList([]);

            if (car.length === 0) {
                setNoCarsFound(true);
            } else {
                const extractedCarList = car.map(c => ({
                    id: c.id,
                    brand: c.brand.brand_name,
                    model: c.model.model_name,
                    varient: c.varient.varient_name,
                }));
                setCarList(extractedCarList);
                setNoCarsFound(false);
            }
        } catch (error) {
            console.error("Error fetching cars:", error);
        }

        window.scrollTo(0, 0);  // Scroll to the top
    };

    // Submit handler for the filter
    const handleFilterSubmit = async () => {
        fetchData(filterData);
        window.scrollTo({ top: 550, behavior: "smooth" });
    };

    // Handle clicking on a suggestion
    const handleSuggestionClick = (suggestion) => {
        window.location.href = `/explore-car/${suggestion.model}`;
    };

    return (
        <div>
           

            {/* Car List */}
            <div className="car-list-container">
                {noCarsFound && <p>No cars found</p>}
                {carList.length > 0 && (
                    <ul className="car-list">
                        {carList.map((car, index) => (
                            <li key={index}>
                                <a href={`/car-details/${car.brand.replace(/\s+/g, '-').toLowerCase()}/${car.id}`}>
                                    <strong>{car.brand} {car.model} {car.varient}</strong>
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* Banner Component */}
            <Banner />

            {/* Other Components */}
            <HighLights />
            <EmiCalculator />
            <RecentSearch />
            <TrendingSearches />
            <PopularBrands />
            <Testimonial />
        </div>
    );
}

export default Home;
