import React, { useEffect } from "react";

const Faq = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="faqDiv">
      <section class="DefaultTopBanner p-5"><div class="container">
        <div class="row">
          <div className="row white"><h1>Frequently Asked Questions (FAQs)</h1></div>
        </div>
      </div>
      </section>
      <div className="container mt-5">

        <div className="row mt-3">
          <h3 className="  divTitle">For Dealers:</h3>
          <ol className="listOl">
            <li className="faqList">
              <h5>Q: How can I register as a dealer on Used Car Wale?</h5>
              <ul>
                <li >A: To register as a dealer, simply click on the "Dealer Registration" link on our homepage and complete the required information. Once your account is approved, you'll have access to a range of tools to manage and list your inventory.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: What are the benefits of listing as a dealer on Used Car Wale?</h5>
              <ul>
                <li>A: As a dealer, you'll receive enhanced visibility on our platform, the ability to list multiple vehicles, access to premium advertising options, and advanced analytics to track your listings' performance.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: Are there any fees for dealers to list cars on Used Car Wale?</h5>
              <ul>
                <li>A: We offer both free and paid listing options for dealers. Free listings provide basic visibility, while paid listings offer premium placements and extended durations. For detailed pricing, contact our call centre.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: How can I manage my listings?</h5>
              <ul>
                <li>A: Once registered, dealers can log into their account dashboard where they can easily add, edit, renew, or remove vehicle listings.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: Can I bulk upload my inventory?</h5>
              <ul>
                <li>A: Yes, dealers can bulk upload their inventory (without vehicle photos) using our CSV upload feature, making it easy to list multiple vehicles at once.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: What kind of support do dealers receive?</h5>
              <ul>
                <li>A: We provide dedicated customer support for dealers (from 10:00 am to 8:00 pm), including assistance with listing setup, advertising options, and any technical issues that may arise.</li>
              </ul>
            </li>
          </ol>

          <h3 className="  divTitle">For Individuals:</h3>
          <ol className="listOl">
            <li className="faqList">
              <h5>Q: How do I list my used car for sale on Used Car Wale?</h5>
              <ul>
                <li>A: Listing your car is easy! Just click on "Sell Your Car" on our homepage, fill in the necessary details about your vehicle, upload photos, and submit your listing. Your ad will be live after a quick review.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: Is there a fee to list my car on Used Car Wale?</h5>
              <ul>
                <li>A: We offer both free and paid listing options for individual sellers. Free listings have a limited duration, while paid listings can enhance visibility and stay live for longer periods.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: What information do I need to provide to list my car?</h5>
              <ul>
                <li>A: You will need to provide the make, model, year, mileage, condition, and other relevant details of your vehicle, along with high-quality photos.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: How long will my listing stay active?</h5>
              <ul>
                <li>A: Free listings are active for 7 days. You can renew your listing before it expires or upgrade to a paid listing for extended visibility.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: Can I edit or update my listing after it's posted?</h5>
              <ul>
                <li>A: Yes, you can edit your listing at any time by logging into your account. You can update the price, description, and photos as needed.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: How do I respond to inquiries from potential buyers?</h5>
              <ul>
                <li>A: When someone is interested in your car, you will receive an email notification with their contact details. You can then communicate directly with the buyer to arrange a viewing or discuss the sale.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: What should I do once my car is sold?</h5>
              <ul>
                <li>A: Once your car is sold, please log into your account and mark the listing as sold or remove it from the platform to avoid further inquiries.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">Q: Is my personal information safe on Used Car Wale?</h5>
              <ul>
                <li>A: Yes, we take your privacy seriously. Your personal information is protected and only shared with potential buyers as needed to facilitate the sale.</li>
              </ul>
            </li>
          </ol>

          <h3 className="  divTitle">Benefits of Listing Used Cars on Used Car Wale:</h3>
          <ol className="listOl">
            <li className="faqList">
              <h5>1- Wide Reach & Visibility:</h5>
              <ul>
                <li>Listing your car on Used Car Wale exposes it to a large audience of potential buyers across India, increasing your chances of a quick sale.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">2- User-Friendly Platform:</h5>
              <ul>
                <li>Our platform is designed to make the listing process simple and efficient, allowing you to upload your vehicle details and photos in just a few easy steps.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">3- Free & Paid Listing Options:</h5>
              <ul>
                <li>Whether you're looking for a basic free listing or a premium paid option with enhanced visibility, we offer flexible choices to suit your needs.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">4- Trust & Credibility:</h5>
              <ul>
                <li>Listing your car on a reputable platform like Used Car Wale adds credibility, making buyers more confident in the legitimacy of your vehicle.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">5- Detailed Vehicle Listings:</h5>
              <ul>
                <li>Our platform allows you to provide comprehensive details about your car, including high-quality images, which helps attract serious buyers and reduces the need for lengthy negotiations.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">6- 24/7 Access:</h5>
              <ul>
                <li>Your listing is accessible to potential buyers at any time, allowing for continuous exposure and inquiries, even outside of regular business hours.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">7- Dedicated Customer Support:</h5>
              <ul>
                <li>Our support team is always available (from 10:00 am to 8:00 pm) to assist you with any questions or issues you may encounter during the listing process.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">8- Enhanced Communication Tools:</h5>
              <ul>
                <li>Directly communicate with interested buyers through our platform, streamlining the negotiation process and ensuring clear, effective communication.</li>
              </ul>
            </li>
          </ol>
          <h3 className="  divTitle">Why Used Car Wale Inspection is Necessary:</h3>
          <ol className="listOl">
            <li className="faqList">
              <h5>1- Accurate Valuation:</h5>
              <ul>
                <li>Our thorough inspection process ensures that your vehicle is accurately valued based on its current condition, helping you set a fair and competitive price.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">2- Builds Buyer Confidence:</h5>
              <ul>
                <li>An inspection report from Used Car Wale provides buyers with detailed information about the car's condition, increasing their confidence and trust in your listing.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">3- Transparency & Honesty:</h5>
              <ul>
                <li>By conducting a professional inspection, you demonstrate transparency in the sale, which can reduce the chances of disputes and improve buyer satisfaction.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">4- Increased Marketability:</h5>
              <ul>
                <li>Cars with a verified inspection report are more attractive to buyers, as they can be assured of the vehicle's quality and condition before making a purchase decision.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">5- Highlight Key Selling Points:</h5>
              <ul>
                <li>Our inspection process not only identifies issues but also highlights the strengths of your vehicle, allowing you to emphasize these points in your listing.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">6- Reduces Time to Sell:</h5 >
              <ul>
                <li>An inspected car is likely to sell faster since buyers have all the necessary information upfront, reducing the need for prolonged negotiations or additional checks.</li>
              </ul>
            </li>
            <li className="faqList">
              <h5 className="mt-3">7- Professional Evaluation:</h5>
              <ul>
                <li>Our team of experts conducts the inspection using industry-standard practices, ensuring an objective and comprehensive evaluation of your vehicle.</li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Faq;