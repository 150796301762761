import React, { useState, useEffect } from "react";
import logo from "../images/logo/usedcarwalelogo.png"; // Import logo
import "../App.css"; // Import App.css
import { Link } from "react-router-dom";
import axios from "axios";

function Header({ setShowUserModal, setShowDealerModal, updateLoginState }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfileVisible, setUserProfileVisible] = useState(false);
  const userid = JSON.parse(localStorage.getItem("user_info"))?.id;
  const [filterData, setFilterData] = useState({
    search_text: "",
  });
  const [suggestions, setSuggestions] = useState([]); // New state for suggestions

  useEffect(() => {
    const authUser = localStorage.getItem("authUser  ");
    const userid = JSON.parse(localStorage.getItem("user_info"))?.id;
    if (authUser && userid) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage items
    setIsLoggedIn(false);
    window.location.href = "/";
  };
  const handleLogin = () => {
    updateLoginState(true);
  };

  const handleUserProfileClick = () => {
    setUserProfileVisible(!userProfileVisible);
  };

  // Handler for input/select changes with real-time suggestion fetch
  const handleSelect = async (e, name) => {
    const searchText = e.target.value;
    setFilterData({
      ...filterData,
      [name]: searchText,
    });

    // Fetch suggestions if there is some input
    if (searchText) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/car/car-list?search_text=${searchText}`
        );
        const { car } = response?.data;

        if (car.length === 0) {
          setSuggestions([]);
        } else {
          const extractedSuggestions = car.map(c => ({
            id: c.id,
            brand: c.brand.brand_name,
            model: c.model.model_name,
            varient: c.varient.varient_name,
          }));
          setSuggestions(extractedSuggestions);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }
  };

  // Fetch data from API and extract brand, model, and variant
  const fetchData = async (filterDataObj) => {
    let queryParams = new URLSearchParams();
    if (filterDataObj.search_text) {
      queryParams.append('search_text', filterDataObj.search_text);
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/car/car-list?${queryParams}`
      );
      const { car } = response?.data;

      // Clear previous car list
      // setCarList([]);

      if (car.length === 0) {
        // setNoCarsFound(true);
      } else {
        const extractedCarList = car.map(c => ({
          id: c.id,
          brand: c.brand.brand_name,
          model: c.model.model_name,
          varient: c.varient.varient_name,
        }));
        // setCarList(extractedCarList);
        // setNoCarsFound(false);
      }
    } catch (error) {
      console.error("Error fetching cars:", error);
    }

    window.scrollTo(0, 0);  // Scroll to the top
  };

  // Submit handler for the filter
  const handleFilterSubmit = async () => {
    fetchData(filterData);
    window.scrollTo({ top: 550, behavior: "smooth" });
  };

  // Handle clicking on a suggestion
  const handleSuggestionClick = (suggestion) => {
    window.location.href = `/explore-car/${suggestion.model}`;
  };

  return (
    <section className="header" id="header">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-md-7 col-sm-12 col-12">
            <div className="card border-none bg-none">
              <nav className="d-flex">
                <Link to="/" className="navbar-brand">
                  <img src={logo} className="App-logo" alt="logo" />
                </Link>
                {/* Search Box */}
                <div className="search-box-container headerSearch">
                  <div className="search-box">
                    <input
                      className="form-control px-5 search-input"
                      type="text"
                      value={filterData.search_text}
                      onChange={(e) => handleSelect(e, "search_text")}
                      placeholder="Search for car models..."
                    />
                    {/* Suggestions Dropdown */}
                    {suggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                          <li key={index}>
                            <a href={`/explore-car/${suggestion.model}`}>
                              <strong>
                                {suggestion.brand} {suggestion.model} {suggestion.varient}
                              </strong>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                    <button
                      className="btn search-btn"
                      onClick={handleFilterSubmit}
                    >
                      Search
                    </button>
                  </div>
                </div>

              </nav>
            </div>
          </div>
          <div className="col-xl-5 col-md-5 col-sm-12 col-12 nav-items">
            <div className="card bg-none border-none">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {userid ? (
                  <>
                    <li>
                      <button onClick={handleLogout} className="loginSubitems">
                        Logout
                      </button>
                    </li>
                    <li>
                      <button
                        className="loginSubitems"
                        onClick={handleUserProfileClick}
                      >
                        <ion-icon name="person-circle-outline"></ion-icon> <Link to="/profile">
                          User-Profile
                        </Link>
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="navbar-dropdown">
                      <h6 className="DropdownHeading">
                        Login <ion-icon name="chevron-down-outline"></ion-icon>
                      </h6>

                      <div className="dropdown">
                        <button
                          className="loginSubitems"
                          onClick={() => {
                            setShowUserModal(true);
                            handleLogin();
                          }}
                        >
                          <ion-icon name="home-outline"></ion-icon> USER LOGIN
                        </button>
                      </div>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/profile" target="_blank" className="btn">
                    TALK TO AN EXPERT &nbsp;{" "}
                    <ion-icon name="logo-whatsapp"></ion-icon>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;