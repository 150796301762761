import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import DealerLoginModal from "./components/modals/DealerLoginModal"; // Import DealerLoginModal
import ExploreCar from "./Pages/ExploreCar";
import SellCar from "./Pages/SellCar";
import CarDetails from "./Pages/CarDetails";
import CarValuation from "./Pages/CarValuation";
import ListedCarsDetails from "./Pages/ListedCarsDetails";
import Profile from "./Pages/Profile";
import Inspection from "./Pages/Inspection";
import Footer from "./components/Footer";
import Home from "./components/Home";
import LoginModal from "./components/modals/LoginModal";
import CalculateFinalValue from "./Pages/CalculateFinalValue";
import RequestCallBack from "./components/RequestCallBack";
import ThankYou from "./components/ThankYou";
import InspectionThankYou from "./components/InspectionThankYou";
import AboutUs from "./Pages/AboutUs"
import TermsCondition from "./Pages/TermsCondition"
import Faq from "./Pages/Faq"

function App() {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDealerModal, setShowDealerModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const updateLoginState = (state) => {
    setIsLoggedIn(state);
  };

  return (
    <Router>
      <Header
        setShowUserModal={setShowUserModal}
        updateLoginState={updateLoginState}
      />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/explore-car/:brandName?" component={ExploreCar} />
        <Route path="/inspection-car" component={Inspection} />
        <Route path="/sell-car" component={SellCar} />
        {/* Updated CarDetails route for SEO */}
        <Route path="/car-details/:carModel/:carId" component={CarDetails} />
        <Route path="/car-valuation" component={CarValuation} />
        <Route path="/listed-card-details" component={ListedCarsDetails} />
        <Route path="/final-value" component={CalculateFinalValue} />
        <Route path="/request-call-back" component={RequestCallBack} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/terms-condition" component={TermsCondition} />
        <Route path="/faq" component={Faq} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/inspection-thank-you" component={InspectionThankYou} />
        <ProtectedRoute path="/profile" component={Profile} />
      </Switch>
      <Footer />
      {showUserModal && (
        <LoginModal
          pathRoute={"/profile"}
          onClose={() => setShowUserModal(false)}
          onLogin={() => updateLoginState(true)}
        />
      )}
      {/* {showDealerModal && (
        <DealerLoginModal
          onClose={() => setShowDealerModal(false)}
          onLogin={() => updateLoginState(true)}
        />
      )} */}
    </Router>
  );
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userid = JSON.parse(localStorage.getItem("user_info"))?.id;

  return (
    <Route
      {...rest}
      render={(props) =>
        userid ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default App;
