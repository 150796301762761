import React, { useEffect } from "react";


const AboutUs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (

    <div className="aboutUs">
      <section class="DefaultTopBanner p-5"><div class="container">
        <div class="row">
          <div className="row white"><h1>About Us</h1></div>
        </div>
      </div>
      </section>

      <div className="container mt-5">



        <div className="row mt-3">
          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5>Why Used Car Wale?</h5>

                <p>
                  It is advisable to estimate the value of your car and have an
                  idea of its correct price before selling it. This will help you
                  make informed decisions and negotiate effectively with potential
                  buyers. By using our car valuation services, you can get an
                  accurate assessment of your car's worth based on various factors
                  such as its make, model, year of manufacture, condition, and
                  mileage. Understanding the fair market value of your car will
                  empower you to set a competitive and reasonable selling price,
                  ensuring a smooth and satisfactory selling experience.
                </p>
              </div>
            </section>
          </div>

          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5>Our Commitment</h5>
                <p>
                  Buying and selling used cars can indeed be a challenging
                  endeavor. One of the crucial aspects of such transactions,
                  whether you're buying or selling, is ensuring a fair price. At
                  Used Car Wale, we understand the significance of this principle
                  and share the same philosophy of never underselling or
                  overbuying. When it comes to selling your car, it's essential to
                  receive a fair and reasonable price that accurately reflects its
                  value. On the other hand, as a buyer, you want to ensure that
                  you're not overpaying for a used car. By utilizing Used Car
                  Wale's services, you can confidently navigate the process,
                  knowing that our commitment to fairness will guide the pricing
                  recommendations.
                </p>
              </div>
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5>The Artificial Intelligence behind Used Car Wale</h5>

                <p>
                  Used Car Wale (UCW) features the most advanced algorithmic and
                  data science-based value calculator for used cars, bikes, and
                  scooters in India. Unlike conventional pricing engines, Used Car
                  Wale employs proprietary data science methodologies and
                  cutting-edge technologies to accurately determine vehicle prices
                  based on various depreciation factors. These factors include the
                  make, model, trim, odometer reading for kilometers driven, and
                  the current condition of the vehicle, taking into account wear
                  and tear, dents, and major repairs.
                </p>
              </div>
            </section>
          </div>

          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5> How Used Car Wale Valuation Tool Works?</h5>
                <p>
                  The used car prices and car resale value calculator is an
                  invaluable tool for individuals seeking to sell their vehicles
                  directly, bypassing intermediaries and avoiding additional
                  costs. In India, banks and BFCs rely on car pricing
                  certification services provided by this tool to determine the
                  value of used cars before finalizing auto loan write-offs.
                  Insurance companies also utilize the tool to calculate the
                  Insured Declared Value (IDV) of pre-owned cars.
                </p>
              </div>
            </section>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5> What is Used Car Wale?</h5>
                <p>
                  The value of a car diminishes over time, influenced by a
                  multitude of factors that affect its resale worth. These factors
                  include the year of purchase, the brand and model, the total
                  distance covered, and the overall condition of the vehicle. The
                  process of assessing the value of a used car considers all these
                  variables, utilizing a pricing model that integrates data from
                  countless buyers and sellers in the used car market. At Used Car
                  Wale, we leverage this extensive data to determine the most
                  precise price range for your car, ensuring that you receive an
                  accurate valuation that reflects its current market value.
                </p>
              </div>
            </section>
          </div>

          <div className="col-md-6">
            <section className="section">
              <div className="section-content">
                <h5> Our Services</h5>
                <p>
                  At Used Car Wale, we offer a range of services designed to
                  assist both buyers and sellers in making informed decisions. Our
                  comprehensive valuation tools, powered by advanced data science
                  and machine learning technologies, ensure that you get the best
                  deal whether you're buying or selling a used car, bike, or
                  scooter. Explore our platform to discover the true worth of
                  your vehicle and simplify the process of valuation.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
